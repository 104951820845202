<template>
  <div>
    <b-modal
      v-model="openModal"
      centered
      header-bg-variant="primary"
      hide-footer
    >
      <h4>
        {{ title }}
      </h4>

      <validation-observer
        ref="bankRules"
      >
        <validation-provider
          #default="{ errors }"
          name="bankName"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ชื่อ</label>
            <b-form-input
              id="bankName"
              v-model="input.name"
              :state="errors.length > 0 ? false:null"
              placeholder="ชื่อบัญชีธนาคาร"
              autofocus
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกชื่อของบัญชีธนาคาร</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="bankAccountName"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>ธนาคาร</label>
            <b-form-group>
              <b-form-select
                v-model="input.bankaccountname"
                :options="optionBank"
                :state="errors.length > 0 ? false : null"
                class="input-color"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >โปรดระบุธนาคาร</small>
            </b-form-group>
          </div>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="bankAccountNumber"
          rules="numeric"
        >
          <div class="col-md-12 mt-1">
            <label>เลขบัญชีธนาคาร</label>
            <b-form-input
              id="agentUrl"
              v-model="input.bankaccountnumber"
              :state="errors.length > 0 ? false:null"
              placeholder="เลขบัญชีธนาคาร"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอกเลขบัญชีธนาคาร</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="bankName"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>Pin</label>
            <b-form-input
              id="bankPin"
              v-model="input.pin"
              :state="errors.length > 0 ? false:null"
              placeholder="ชื่อบัญชีธนาคาร"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอก Pin</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="bankName"
          rules="required"
        >
          <div class="col-md-12 mt-1">
            <label>Device Id</label>
            <b-form-input
              id="bankName"
              v-model="input.deviceid"
              :state="errors.length > 0 ? false:null"
              placeholder="ชื่อบัญชีธนาคาร"
            />
          </div>
          <small
            v-if="errors.length > 0"
            class="text-danger"
          >กรุณากรอก Device Id</small>
        </validation-provider>
      </validation-observer>
      <div class="row mt-2 d-flex justify-content-end">
        <b-button
          variant="danger"
          class="mr-1"
          @click="close"
        >
          ยกเลิก
        </b-button>
        <b-button
          v-if="title === 'เพิ่มข้อมูลบัญชีธนาคาร'"
          variant="success"
          class="mr-1"
          :disabled="isConfirm"
          @click="addBank"
        >
          <b-spinner
            v-if="isConfirm"
            small
          /> ยืนยัน
        </b-button>
        <b-button
          v-if="title === 'แก้ไขข้อมูลบัญชีธนาคาร'"
          variant="warning"
          class="mr-1"
          :disabled="isConfirm"
          @click="editBank"
        >
          <b-spinner
            v-if="isConfirm"
            small
          /> แก้ไข
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BFormInput, BButton, BFormSelect, BFormGroup, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormInput,
    BButton,
    BFormSelect,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      openModal: false,
      numeric,
      input: {
        id: '',
        name: '',
        bankaccountname: null,
        bankaccountnumber: '',
        pin: '',
        deviceid: '',
      },
      optionBank: [
        {
          value: null, text: 'เลือกธนาคาร',
        },
        {
          value: 'SCB', text: 'ธนาคารไทยพาณิชย์',
        },
        {
          value: 'KBANK', text: 'ธนาคารกสิกร',
        },
        {
          value: 'KTB', text: 'ธนาคารกรุงไทย',
        },
        {
          value: 'GSB', text: 'ธนาคารออมสิน',
        },
        {
          value: 'TMB', text: 'ธนาคารทหารไทย',
        },
        {
          value: 'BBL', text: 'ธนาคารกรุงเทพ',
        },
      ],
      required,
      isConfirm: false,
    }
  },
  watch: {
  },
  beforeUpdate() {
    if (this.id !== '') {
      this.getinfo()
    }
  },
  methods: {
    show() {
      this.openModal = true
      this.defaultData()
    },
    close() {
      this.openModal = false
    },
    addBank() {
      this.isConfirm = true
      this.$refs.bankRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('bank/addBank', this.input).then(response => {
            if (response.data.success) {
              // console.log('success')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.close()
              this.$emit('setCloseModal')
              this.$emit('LoadList')
              this.defaultData()
            } else {
              // console.log('fail')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'สมาชิก',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.isConfirm = false
            }
          })
        } else {
          this.isConfirm = false
        }
      })
    },
    editBank() {
      this.isConfirm = true
      this.$refs.bankRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('bank/editBank', this.input).then(response => {
            if (response.data.success) {
              // console.log('success')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.close()
              this.$emit('setCloseModal')
              this.$emit('LoadList')
              this.defaultData()
            } else {
              // console.log('fail')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.isConfirm = false
            }
          })
        } else {
          this.isConfirm = false
        }
      })
    },
    getinfo() {
      this.$store.dispatch('bank/getBankbyId', { id: this.id }).then(response => {
        console.log(response)
        this.input.id = this.id
        this.input.name = response.data.item.name
        this.input.bankaccountname = response.data.item.bankAccountName
        this.input.bankaccountnumber = response.data.item.bankAccountNumber
        this.input.pin = response.data.item.pin
        this.input.deviceid = response.data.item.deviceid
      })
    },
    defaultData() {
      this.input.id = ''
      this.input.name = ''
      this.input.bankaccountname = null
      this.input.bankaccountnumber = ''
      this.input.pin = ''
      this.input.deviceid = ''
      this.isConfirm = false
    },
  },
}
</script>
